.InitPage {
    height: 100vh;
    width: 100vw;
    background: #282828;
    display: flex;
    align-items: center;
    justify-content: center;
}
.logo-container{
    position: relative;
    width: 50%;
    max-width: 450px;
    height: auto;
}
.logo-container > img {
    width: 100%;
    height: auto;
    position: absolute;
    top: 50%;
    transform-origin: 50% 0%;
    left: -8.5%;
}