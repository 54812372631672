.SecondPage {
    min-height: 100vh;
    width: 100vw;
    background: #282828;
    display: flex;
    align-items: center;
    justify-content: center;
}
.second-container{
    position: relative;
    width: 100%;
    max-width: 400px;
    min-height: 100vh;
}
.second-container > .img-holder {
    width: 100%;
    height: 60vh;
    max-height: 600px;
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: cover;
}
.second-container > .text-block{
    overflow: hidden;
    margin-top: 20px;
    color: #FBA91B;
    padding-inline: 43px;
}
.second-container h1, .second-container h2,  .second-container h3{
    margin: 0;
}
.second-container > .text-block > h1 {
    font-size: 2.5rem;
    letter-spacing: 0.20rem;
    font-weight: 300;
}
.second-container > .text-block > h1 > span {
    font-size: 2.65rem;
    letter-spacing: initial;
    font-weight: 400;
}
.second-container h3 {
    margin-top: 10px;
    font-weight: 400;
    font-size: 1.1rem;
}
.second-container > .icons-block {
    overflow: hidden;
    height: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 10px;
    padding-inline: 43px;
    width: 100%;
}
.second-container > .icons-block > .row {
    display: flex;
    gap: 10px;
    flex-wrap: nowrap;
}
.second-container > .icons-block > .row > img {
    width: 100px;
}
.second-container > .social {
    margin-top: 45px;
    gap: 35px;
    padding-inline: 43px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
}
.second-container > .social > a {
    transform: scale(0);
}
.second-container > .buttons {
    padding-top: 45px;
    padding-bottom: 175px;
    gap: 25px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    padding-inline: 43px;
}
.second-container > .buttons > a {
    border-radius: 5px;
    text-align: center;
    width: 100%;
    padding: 10px 0;
    outline: none;
    border: none;
    background: #FBA91B;
    text-decoration: none;
    color: #282828;
    font-size: 1.1rem;
    font-family: 'Inter', sans-serif;
}